<!-- 실천활동 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">실천활동</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row">
                <div v-if="verboseSearchOptionSemester" class="col-sm-2">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div v-if="inputPossibleManager" class="col text-end">
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                </div>
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h1>수업</h1>
                <form role="form">
                  <div class="row-sm-3">
                    <preset-radio
                      v-model="newData.grade"
                      :choices="[
                        ['불교대학', '불교대학'],
                        ['경전대학', '경전대학'],
                      ]"
                    />
                  </div>
                  <div class="row ps-1">
                    <base-input v-model="newData.lessonNo" type="text">
                      <template #addonLeft> 강의번호</template>
                    </base-input>
                    <base-input v-model="newData.type" type="text">
                      <template #addonLeft> 활동구분</template>
                    </base-input>
                    <base-input v-model="newData.title" type="text">
                      <template #addonLeft> 활동명</template>
                    </base-input>
                  </div>

                  <base-input v-model="newData.start" type="date">
                    <template #addonLeft> 시작일</template>
                  </base-input>
                  <base-input v-model="newData.end" type="date">
                    <template #addonLeft> 종료일</template>
                  </base-input>
                  <div class="row">
                    <base-input v-model="newData.urlName" type="text">
                      <template #addonLeft> 법문1제목</template>
                    </base-input>
                    <base-input v-model="newData.url" type="text">
                      <template #addonLeft> 법문1주소</template>
                    </base-input>
                  </div>
                  <div class="row">
                    <base-input v-model="newData.urlName2" type="text">
                      <template #addonLeft> 법문2제목</template>
                    </base-input>
                    <base-input v-model="newData.url2" type="text">
                      <template #addonLeft> 법문2주소</template>
                    </base-input>
                  </div>
                  <div class="row">
                    <base-input v-model="newData.urlName3" type="text">
                      <template #addonLeft> 법문3제목</template>
                    </base-input>
                    <base-input v-model="newData.url3" type="text">
                      <template #addonLeft> 법문3주소</template>
                    </base-input>
                  </div>
                  <div class="row mb-2">
                    <label class="form-control-label">내용</label>
                  </div>
                  <div :key="htmlEditorKey">
                    <html-editor v-model="newData.post" />
                  </div>
                  <preset-radio
                    v-model="newData.state"
                    class="mb-3 mt-3"
                    :choices="[
                      ['1', '열림'],
                      ['0', '닫힘'],
                    ]"
                  />
                  <div class="text-center">
                    <base-button
                      v-if="add"
                      type="primary"
                      class="my-4"
                      @click="onAdd"
                      >등록 하기</base-button
                    >
                    <base-button
                      v-else
                      type="primary"
                      class="my-4"
                      @click="onEdit(newData)"
                      >수정 하기</base-button
                    >
                    <base-button
                      type="secondary"
                      class="my-4 ms-2"
                      @click="closedModal"
                      >닫 기</base-button
                    >
                  </div>
                </form>
              </div>
            </Modal>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.type }}</td>
                    <td>{{ row.title }}</td>
                    <td>{{ row.start }}</td>
                    <td>{{ row.end }}</td>
                    <td>
                      <span :class="row.statusClass">● </span>{{ row.status }}
                    </td>
                    <td width="10%">
                      <div v-if="isAction" class="d-flex pe-4">
                        <base-button
                          v-if="inputPossibleManager"
                          class="edit me-1"
                          type="warning"
                          size="sm"
                          icon
                          @click.stop="handleEdit(row)"
                        >
                          <i class="text-white fa fa-pen" />
                        </base-button>
                        <base-button
                          v-if="inputPossibleManager"
                          class="remove btn-link"
                          type="danger"
                          size="sm"
                          icon
                          @click.stop="handleDelete(row)"
                        >
                          <i class="text-white fas fa-trash-alt"></i>
                        </base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import moment from "moment";
import { SEMESTER } from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    PresetRadio,
    Modal,
    HtmlEditor,
  },
  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "학 년",
      "활동구분",
      "활동명",
      "시작일",
      "종료일",
      "상태",
      "Action",
    ]);
    const query = ref({
      semester: "",
      section: "",
      bound: 20,
    });
    let htmlEditorKey = ref(0);
    let inputPossibleManager = ref(false);
    let inputPossibleMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    const newData = ref({});
    let modalActive = ref(false);
    const add = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    const isAction = ref(false);

    const token = api.Auth.tokenContent;
    // grade.value = token.grade;
    // year.value = 2022;
    // semester.value = "9월";
    query.value.bound = token.listType;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      // const token = api.Auth.tokenContent;
      // query.value.bound = token.listType;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }
      if (year.value == token.year && semester.value == token.semester) {
        isAction.value = true;
      } else {
        isAction.value = false;
      }
      // if (query.value.semester == "불교대학 2022 9월") {
      //   grade.value = "불교대학";
      // } else if (query.value.semester == "경전대학 2022 9월") {
      //   grade.value = "경전대학";
      // }
      if (query.value.section === "전체") {
        query.value.section = "";
      }
      // if (
      //   query.value.semester &&
      //   (token.role === "manager" || token.role === "master")
      // ) {
      //   const temp = query.value.semester.split(" ");
      //   grade.value = temp[0];
      // }
      const url = `manage/class2/weekend?bound=${query.value.bound}
   &page=${page}&grade=${grade.value}&year=${year.value}&semester=${semester.value}&section=${query.value.section}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;

        if (data.rows > 0) {
          for (const row of d) {
            row.start = moment(row.start).format("YYYY-MM-DD");
            row.end = moment(row.end).format("YYYY-MM-DD");
            updateRowStatus(row, row.state);
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      inputPossibleManager.value =
        token.role.includes("manager") || token.role.includes("master");
      inputPossibleMaster.value = token.role.includes("master");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);
    const goMeet = (url) => {
      window.open(url);
    };

    const closedModal = () => {
      modalActive.value = false;
    };
    const updateRowStatus = (row, state) => {
      if (state === 1) {
        row.status = "공개";
        row.statusClass = "text-success";
      } else {
        row.status = "비공개";
        row.statusClass = "text-danger";
      }
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.grade = "";
      newData.value.start = "";
      newData.value.end = "";
      newData.value.title = "";
      newData.value.type = "";
      newData.value.grade = "";
      newData.value.post = "";
      htmlEditorKey.value++;
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/weekend/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        modalActive.value = !modalActive.value;
        const data = response.data.data;
        newData.value = data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/weekend", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/weekend/${row.id}`, { data }).then(() => {
        htmlEditorKey.value++;
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/weekend/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      header,
      page2,
      SEMESTER,
      semester,
      htmlEditorKey,
      newData,
      year,
      query,
      getDataFromApi,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      inputPossibleManager,
      inputPossibleMaster,
      verboseSearchOptionSemester,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      isAction,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
