<!-- 수업 자료실 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">수행연습나누기2</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <h3 class="mb-0 title">일상에서 수행연습 나누기</h3>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <table width="80%">
                <td class="list-group-item px-2" style="text-align: left">
                  <p class="text-left">
                    {{ start1 }}월 {{ start2 }} ~ {{ end1 }}월 {{ end2 }}일
                  </p>
                  <hr />

                  <p v-dompurify-html="post" class="text-left"></p>
                  <div class="text-center mt-4"><br /><br /></div>
                </td>
                <br />
              </table>
            </div>
          </div>
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.grade"
                    placeholder="학년"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      ['불교대학', '불교대학'],
                      ['경전대학', '경전대학'],
                    ]"
                    @change="getDataFromApi(1)"
                  />
                </div>
                <div v-if="verboseSearchOptionManager" class="col-sm-2">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    :choices="LOCAL.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <preset-select
                    v-model="query.team"
                    placeholder="조"
                    :choices="TEAM_NUMBER.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>

                <div class="col">
                  <base-button
                    class="btn-sm-2 mt-3"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & right y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 120px"
              >
                total: {{ total }}
              </div>
            </div>

            <div class="table-responsive">
              <table>
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.local }}</td>
                    <td>{{ row.room }}</td>
                    <td>{{ row.team }}</td>
                    <td>{{ row.name }}</td>
                    <td width="30%">
                      <span>
                        <p v-dompurify-html="row.post"></p>
                      </span>
                    </td>

                    <td>{{ row.createdTime }}</td>

                    <td width="10%">
                      <div class="d-flex pe-4">
                        <base-button
                          v-if="verboseSearchOptionManager"
                          class="remove btn-link"
                          type="danger"
                          size="sm"
                          icon
                          @click.stop="handleDelete(row)"
                        >
                          삭제
                        </base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import moment from "moment";
import { LOCAL, TEAM_NUMBER, ROOM_NUMBER } from "@/consts/class";
export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
  },

  setup() {
    const tableData = ref([]);
    const newData = ref({});
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const fileName = ref("");
    const total = ref(0); // 총 레코드 수
    const query = ref({
      grade: "",
      post: "",
      bound: 20,
      local: "",
      room: "",
      team: "",
    });
    let previewTitle = ref("");
    let previewBody = ref("");
    let showPreviewModal = ref(false);
    let htmlEditorKey = ref(0);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionClassLeader = ref(false);

    let modalActive = ref(false);
    let start1 = ref("");
    let end1 = ref("");
    let start2 = ref("");
    let end2 = ref("");
    let id = ref("");
    let post = ref("");

    const add = ref(false);
    const header = ref([
      "No",
      "지역",
      "반",
      "조",
      "이름",
      "나누기",
      "작성일",
      "Action",
    ]);

    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;

    const getDataFromApi = (page = 1) => {
      page2.value = page;

      let temp2 = window.location.href.split("suid=");

      const url = `manage/class2/practiceRetro/${temp2[1]}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;

        start1.value = moment(d.start).format("M");
        start2.value = moment(d.start).format("D");
        end1.value = moment(d.end).format("M");
        end2.value = moment(d.end).format("D");
        post.value = d.post.split("\n").join("<br>");
        post.value = post.value.replace(
          /<oembed/g,
          '<iframe width="800" height="600" allowfullscreen'
        );
        post.value = post.value.replace(/oembed/g, "iframe");
        post.value = post.value.replace(/url/g, "src");

        post.value = post.value.replace(/watch\?v=/g, "embed/");

        id.value = d.id;
      });
      const url2 = `manage/class2/sharePractice?bound=${query.value.bound}
   &post=${query.value.post}&page=${page}&grade=${query.value.grade}&suid=${temp2[1]}&local=${query.value.local}&room=${query.value.room}&team=${query.value.team}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            updateRowStatus(row, row.state);
            row.createdTime = moment(row.createdTime).format(
              "YYYY-MM-DD HH:mm"
            );

            row.post = row.post.split("\n").join("<br>");
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;

      verboseSearchOptionManager.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
    };
    getDataFromApi(1);
    created();

    const updateRowStatus = (row, state) => {
      if (state === 1) {
        row.status = "공개";
        row.statusClass = "text-success";
      } else {
        row.status = "비공개";
        row.statusClass = "text-danger";
      }
    };
    const goShare = (row) => {
      this.$router.push({
        name: "홈 - 나누기 쓰기",
        query: {
          suid: row.id,
        },
      });
    };

    const goMeet = (url) => {
      window.open(url);
    };
    const closedModal = () => {
      modalActive.value = false;
      showPreviewModal.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.title = "";
      newData.value.post = "";
      newData.value.state = "";
      newData.value.grade = "";
      htmlEditorKey.value++;
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/practiceRetro/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/practiceRetro", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/practiceRetro/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/practiceRetro/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      start1,
      page2,
      start2,
      end1,
      end2,
      post,
      id,
      header,
      LOCAL,
      TEAM_NUMBER,
      ROOM_NUMBER,
      newData,
      query,
      getDataFromApi,
      previewBody,
      previewTitle,
      htmlEditorKey,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionBranch,
      verboseSearchOptionClassLeader,
      modalActive,
      closedModal,
      goShare,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      showPreviewModal,
      fileName,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 1px solid #e9ecef;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #e9ecef;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
